import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import io from 'socket.io-client';

function BettingPage({ userInfo }) {
  const telegramId = userInfo?.telegramId || ''; // Ensure the telegramId is fetched properly

  const [cryptoPrices, setCryptoPrices] = useState({ bitcoin: 0, ethereum: 0 });
  const [historicalPrices, setHistoricalPrices] = useState({ bitcoin: [], ethereum: [] });
  const [error, setError] = useState('');
  const [betAmount, setBetAmount] = useState(0.1);
  const [betType, setBetType] = useState('long');
  const [betHistory, setBetHistory] = useState([]);
  const [balance, setBalance] = useState(100);
  const [timer, setTimer] = useState(60);
  const [selectedAsset, setSelectedAsset] = useState('bitcoin');
  const [pendingBets, setPendingBets] = useState([]);

  const socket = io('https://betbunny.xyz'); // Socket connection for real-time prices

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('/api/crypto-prices');
        const data = await response.json();
        setCryptoPrices({ bitcoin: data.bitcoin, ethereum: data.ethereum });

        setHistoricalPrices((prev) => ({
          bitcoin: [...prev.bitcoin, data.bitcoin],
          ethereum: [...prev.ethereum, data.ethereum],
        }));
      } catch (err) {
        console.error('Error fetching crypto prices:', err);
        setError('Failed to load crypto prices');
      }
    };

    fetchPrices();

    socket.on('crypto-prices', (prices) => {
      setCryptoPrices(prices);
      setHistoricalPrices((prev) => ({
        bitcoin: [...prev.bitcoin, prices.bitcoin].slice(-20),
        ethereum: [...prev.ethereum, prices.ethereum].slice(-20),
      }));
    });

    return () => socket.off('crypto-prices');
  }, [socket]);

  const chartData = {
    labels: Array(historicalPrices[selectedAsset].length).fill(''),
    datasets: [
      {
        label: `Price (${selectedAsset.toUpperCase()} / USDT)`,
        data: historicalPrices[selectedAsset],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const placeBet = async () => {
    console.log("Attempting to place bet with userId:", telegramId); // Debugging log

    if (!telegramId) {
      setError('User ID is required to place a bet.');
      return;
    }

    try {
      const response = await fetch('/api/placeBet', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: telegramId, // Use the Telegram user ID
          betType,
          amount: betAmount,
          asset: selectedAsset,
          initialPrice: cryptoPrices[selectedAsset],
        }),
      });

      const data = await response.json();
      console.log("Bet placed response:", data); // Debugging log

      if (!response.ok) {
        throw new Error(data.message || 'Failed to place bet');
      }

      setBetHistory([...betHistory, data.bet]);
      setPendingBets([...pendingBets, data.bet]);
    } catch (error) {
      console.error("Bet placement error:", error); // Debugging log
      setError('Error: ' + error.message);
    }
  };

  const resolveBets = () => {
    const updatedHistory = pendingBets.map((bet) => {
      const finalPrice = cryptoPrices[bet.asset.toLowerCase()];
      const result =
        (bet.betType === 'long' && finalPrice > bet.initialPrice) ||
        (bet.betType === 'short' && finalPrice < bet.initialPrice)
          ? 'Win'
          : 'Lose';

      const updatedBet = { ...bet, result };
      if (result === 'Win') {
        setBalance((prev) => prev + parseFloat(bet.amount));
      } else {
        setBalance((prev) => prev - parseFloat(bet.amount));
      }

      return updatedBet;
    });

    setBetHistory(updatedHistory);
    setPendingBets([]);
    localStorage.setItem('betHistory', JSON.stringify(updatedHistory));
    localStorage.setItem('balance', balance);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval);
      resolveBets();
      setTimer(60);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div>
      <h1>Betting Page</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <h2>Crypto Prices</h2>
      <p>Bitcoin: {cryptoPrices.bitcoin || 'Loading...'}</p>
      <p>Ethereum: {cryptoPrices.ethereum || 'Loading...'}</p>

      <Line data={chartData} />

      <h2>Timer: {timer} seconds</h2>

      <h2>Select Asset</h2>
      <select value={selectedAsset} onChange={(e) => setSelectedAsset(e.target.value)}>
        <option value="bitcoin">Bitcoin</option>
        <option value="ethereum">Ethereum</option>
      </select>

      <h2>Place Your Bet</h2>
      <input
        type="number"
        value={betAmount}
        onChange={(e) => setBetAmount(e.target.value)}
        min="0.01"
      />
      <select value={betType} onChange={(e) => setBetType(e.target.value)}>
        <option value="long">Long</option>
        <option value="short">Short</option>
      </select>
      <button onClick={placeBet}>Place Bet</button>

      <h2>Balance: {balance.toFixed(2)}</h2>

      <h2>Bet History</h2>
      {betHistory.length === 0 ? (
        <p>No bets placed yet</p>
      ) : (
        <ul>
          {betHistory.map((bet, index) => (
            <li key={index}>
              {bet.asset} ({bet.betType}): {bet.result}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default BettingPage;
