import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BettingPage from './pages/BettingPage';

function App() {
  const [telegramData, setTelegramData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    username: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready();

      const user = webApp.initDataUnsafe?.user;
      if (user && user.id) {
        setTelegramData({
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          username: user.username,
        });
      } else {
        setError('User data not available');
      }
    } else {
      setError('Telegram WebApp object not available. Please open the app in Telegram.');
    }
  }, []);

  return (
    <Router>
      <div>
        <h1>Welcome, {telegramData.username || 'Guest'}!</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <Routes>
          <Route
            path="/"
            element={<BettingPage userInfo={{ telegramId: telegramData.id }} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
